var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"fill"}},[_c('ToggleExpanded',{attrs:{"refs":_vm.$refs}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('span',{staticClass:"text-center mr-3"},[_vm._v(" Vendor Totals by Store ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Store Filter","items":_vm.stores,"background-color":"input","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.selectedStore),callback:function ($$v) {_vm.selectedStore=$$v},expression:"selectedStore"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Vendor Filter","items":_vm.vendors,"background-color":"input","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.selectedVendor),callback:function ($$v) {_vm.selectedVendor=$$v},expression:"selectedVendor"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.filter ? 'Press Enter to Search' : 'Search Recap',"prepend-inner-icon":"mdi-magnify","hide-details":"","outlined":"","dense":"","background-color":"input","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"height":_vm.tableSize,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.currentRecaps,"color":"#fff","loading-text":"Loading... Please wait","fixed-header":"","items-per-page":_vm.pageSize,"footer-props":{'items-per-page-options': _vm.pageSizes},"group-by":_vm.groupKey,"dense":"","search":_vm.filter},on:{"update:items":function($event){_vm.currentRecaps=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[_c('v-icon',[_vm._v(_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),_c('v-col',[_vm._v(_vm._s(group))]),_c('v-col',{staticClass:"ml-auto mr-6",attrs:{"cols":"auto"}},[_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(" Total Allowance: $"+_vm._s(_vm.sumAllowance(_vm.groupKey, group, _vm.currentRecaps))+" ")])])],1)],1)]}},{key:"item.allowance_earned",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm.formatCurrencyDisplay(item.allowance_earned)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }