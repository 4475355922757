<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <ToggleExpanded :refs="$refs"></ToggleExpanded>
      <v-divider vertical inset class="mx-4" />
      <span class="text-center">
        Promotions
      </span>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          :label="filter ? 'Press Enter to Search' : 'Search Recap'"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          v-model="filter"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider />
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="promoItems"
      :items-per-page.sync="pageSize"
      group-by="promoNameNum"
      color="#fff"
      loading-text="Loading... Please wait"
      fixed-header
      hide-default-footer
      dense
      :search="filter">
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn dense @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon>
              {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
          {{ group }} 
        </td>
      </template>

      <template #[`item.allowance`]="{item}">
        <span>${{formatCurrencyDisplay(item.allowance)}}</span>
      </template>

      <template #[`item.variance`]="{item}">
        <span>${{formatCurrencyDisplay(item.variance)}}</span>
      </template>

      <template #[`item.unitsrp`]="{item}">
        <span>${{formatCurrencyDisplay(item.unitsrp)}}</span>
      </template>

      <template v-slot:footer>
        <v-divider />
        <v-toolbar dense flat color="#fafafa">
          <v-row align="center" justify="end">
            <v-col cols="2" style="max-width:150px;">
              <v-select
                class="mt-8"
                dense
                :items="pageSizes"
                label="Items Per Page"
                v-model="pageSize"
                :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
              </v-select>
            </v-col>
            <v-btn
              icon
              large
              @click="previous"
              :disabled="disablePrevious"
              class="mx-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn
              icon
              large
              @click="next"
              :disabled="disableNext"
              class="mx-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
// Components
import ToggleExpanded from '@/components/ToggleExpanded.vue'
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/displayAlert'
export default {
  name: 'promo-item-table',
  data () {
    return {
      filter: '',
      loading: false,
      search: '',
      promoItems: [],
      page: 1,
      pageSize: 100,
      pageSizes: [50, 100]
    }
  },
  components: { ToggleExpanded },
  mixins: [fullWidth, format, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    },
    pageSize: {
      handler () {
        if (this.page > 1) this.page = 1
        this.getPromoItems()
      }
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.getPromoItems()
  },
  computed: {
    offset () {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious () {
      return this.offset === 0
    },
    disableNext () {
      return this.promoItems.length < this.pageSize
    },
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Item ID', align: 'left', sortable: true, filterable: true, value: 'item_id', class: 'black--text' },
        { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'description', class: 'black--text' },
        { text: 'Promotion Category', align: 'left', sortable: true, filterable: true, value: 'promo_category_name', class: 'black--text' },
        { text: 'Allowance', align: 'left', sortable: true, filterable: true, value: 'allowance', class: 'black--text' },
        { text: 'Variance', align: 'left', sortable: true, filterable: true, value: 'variance', class: 'black--text' },
        { text: 'Retail Price', align: 'left', sortable: true, filterable: true, value: 'unitsrp', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'upc', class: 'black--text' },
        { text: 'Vendor Id', align: 'left', sortable: true, filterable: true, value: 'vendor_id', class: 'black--text' },
        { text: 'Vendor Name', align: 'left', sortable: true, filterable: true, value: 'vendor_name', class: 'black--text' }
      ]
    }
  },
  methods: {
   async getPromoItems() {
      this.loading = true
      let promoItems = []
      try {
        const res = await this.$BillingBatch.getBatchPromo(this.selectedBatch.id, this.pageSize, this.offset)
        if (res?.data?.length > 0) {
          promoItems = res.data.map(item => {
            item.promoNameNum = `${item.promo_name} -- ${item.promo_number}`
            return item
          })
        }
        this.promoItems = promoItems
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
   },
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getPromoItems()
    },
    next () {
      this.page = this.page + 1
      this.getPromoItems()
    }
  }
}
</script>