<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <span class="text-center ml-2">Store Allowance Totals</span>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          v-model="filter"
          label="Search Recap"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="stores"
      :items-per-page="-1"
      :footer-props="{'items-per-page-options': [-1]}"
      color="#fff"
      loading-text="Loading... Please wait"
      fixed-header
      dense
      item-key='party_id'
      sort-by="store_name_num"
      :search="filter">
      <template #[`item.allowance_earned`]="{item}">
        <span>${{ formatCurrencyDisplay(item.allowance_earned) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/displayAlert'
export default {
  name: 'store-table',
  data () {
    return {
      filter: '',
      loading: false,
      search: '',
      stores: []
    }
  },
  mixins: [fullWidth, format, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.getStoreRecap()
  },
  computed: {
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Store Name/Number', sortable: true, filterable: true, value: 'store_name_num', class: 'black--text' },
        { text: 'Total Allowance', align: 'left', sortable: true, filterable: true, value: 'allowance_earned', class: 'black--text' }
      ]
    }
  },
  methods: {
    async getStoreRecap() {
      const stores = []
      this.loading = true
      try {
        const generator = this.$BillingBatch.generate('getStoreRecaps', this.selectedBatch.id, 200, 0)
        for await (const results of generator) {
          stores.push(...results)
        }
        this.stores = stores
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
