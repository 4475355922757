<template>
  <v-card flat>
     <v-toolbar flat color="fill">
      <ToggleExpanded :refs="$refs"></ToggleExpanded>
      <v-divider vertical inset class="mx-4" />
      <span class="text-center">
        Ineligible Items
      </span>
      <v-spacer></v-spacer>
      <v-col cols="3" class="pl-0">
        <v-text-field
          v-model="filter"
          label="Search Recap"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="ineligible_items"
      :items-per-page="pageSize"
      :footer-props="{'items-per-page-options': pageSizes}"
      color="#fff"
      loading-text="Loading... Please wait"
      fixed-header
      dense
      item-key='party_id'
      group-by="store_name_number"
      :search="filter">
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-row dense>
            <v-btn
              @click="toggle"
              small
              icon
              :ref="group"
              :data-open="isOpen">
              <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <v-col>{{ group }}</v-col>
            <v-col style="display: grid; justify-content: flex-end; padding-right: 30px;">
              <span style="display: inline-block">
                Total Missed Allowance: ${{ sumMissedAllowance(group) }}
              </span>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #[`item.missed_allowance`]="{item}">
        <span>${{formatCurrencyDisplay(item.missed_allowance)}}</span>
      </template>
      <template #[`item.variance`]="{item}">
        <span>${{formatCurrencyDisplay(item.variance)}}</span>
      </template>
      <template #[`item.promo_unit_price`]="{item}">
        <span>${{formatCurrencyDisplay(item.promo_unit_price)}}</span>
      </template>
      <template #[`item.basket_unit_price`]="{item}">
        <span>${{formatCurrencyDisplay(item.basket_unit_price)}}</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
// Components
import ToggleExpanded from '@/components/ToggleExpanded.vue'
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/displayAlert'
export default {
  name: 'ineligible-table',
  data () {
    return {
      filter: '',
      loading: false,
      search: '',
      ineligible_items: [],
      pageSize: 100,
      pageSizes: [50, 100, 200, -1]
    }
  },
  components: { ToggleExpanded },
  mixins: [fullWidth, format, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.getIneligibleRecap()
  },
  computed: {
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Promo Name', align: 'left', sortable: true, filterable: true, value: 'promo_name', class: 'black--text' },
        { text: 'Item ID', align: 'left', sortable: true, filterable: true, value: 'item_id', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'upc', class: 'black--text' },
        { text: 'Promotion Price', align: 'left', sortable: false, filterable: false, value: 'promo_unit_price', class: 'black--text' },
        { text: 'Retail Price', align: 'left', sortable: true, filterable: true, value: 'basket_unit_price', class: 'black--text' },
        { text: 'Variance', align: 'left', sortable: true, filterable: true, value: 'variance', class: 'black--text' },
        { text: 'Missed Allowance', align: 'left', sortable: false, filterable: false, value: 'missed_allowance', class: 'black--text' }
      ]
    }
  },
  methods: {
    sumMissedAllowance(value) {
      const allowances = this.ineligible_items.flatMap(result => {
        return (result?.store_name_number === value) ? result.missed_allowance : []
      })
      const sum = allowances.reduce((total, value) => {
        return total + value
      }, 0)
      return this.formatCurrencyDisplay(sum)
    },
    async getIneligibleRecap() {
      const { id } = this.selectedBatch
      let recaps = []
      this.loading = true
      try {
        const generator = this.$BillingBatch.generate('getIneligibleRecap', id, 100000, 0)
        for await (const results of generator) {
          recaps.push(...results)
        }
        this.ineligible_items = recaps
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
