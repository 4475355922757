<!-- Modal to display information pertaining to a batch. This modal imports the BatchTables as tabs to show different information -->
<template>
<v-dialog  justify="center" :value="value" persistent>
<v-card color=#fafafa>
  <v-container class="ma-0 pa-0" fluid>
    <v-expansion-panels flat v-model="expandedPanel">
      <v-expansion-panel>
        <v-tooltip top color="main">
          <template v-slot:activator="{ on }">
            <v-expansion-panel-header v-on="on" color="grey lighten-3">
              <template v-slot:default>
                <v-row align="center">
                    <v-btn icon @click.stop="$emit('update:value', false)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-card-title class="mt-0">
                      Billing Recaps
                    </v-card-title>
                  <v-spacer />
                  <small v-if="show">
                    <strong>Batch Dates:</strong> {{selectedBatch.start_date}} - {{selectedBatch.end_date}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Batch Name:</strong> {{selectedBatch.name}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Batch Type:</strong> {{selectedBatch.batch_type}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Responsible Party:</strong> {{getResponsiblePartyName(selectedBatch.responsible_party_id)}}
                    <span v-if="selectedBatch.status">
                      <v-icon color="#000">mdi-circle-small</v-icon>
                      <strong>Status:</strong> {{selectedBatch.status }}
                    </span>
                  </small>
                  <v-spacer />
                </v-row>
              </template>
            </v-expansion-panel-header>
          </template>
          <span>
            <v-icon small color="#fff">
              {{ (show) ? 'mdi-arrow-expand-vertical' : 'mdi-arrow-collapse-vertical' }}
            </v-icon>
            {{ (show) ? 'Expand Batch Details' : 'Collapse Batch Details' }}
          </span>
        </v-tooltip>
        <v-expansion-panel-content color="grey lighten-3">
          <v-row>
            <v-col>
              <v-text-field
                :value="selectedBatch.name"
                label="Batch Name"
                outlined
                readonly
                hide-details
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :value="formatDate(selectedBatch.start_date)"
                label="Start Date"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                hide-details
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :value="formatDate(selectedBatch.end_date)"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                hide-details
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :value="formatDateTime(selectedBatch.last_update_date)"
                label="Last Updated"
                outlined
                readonly
                hide-details
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                :value="selectedBatch.batch_type"
                label="Batch Type"
                outlined
                readonly
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :value="respParty"
                label="Responsible Party"
                outlined
                readonly
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="selectedBatch.status"
                label="Status"
                outlined
                readonly
                background-color="input"
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn 
                color="#FFB74D" 
                @click="modal = true">
                Rebill/Reverse
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn 
                :disabled="invalidStatus"
                color="success" 
                @click="downloadRecaps">
                Download Recaps 
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template>
      <v-divider />
      <v-tabs
        grow
        dark
        background-color="#37474F"
        height="40"
        hide-slider
        :active-class="'active'"
        v-model="tab">
        <v-tab :ripple="false" v-for="item in currentTabs" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <keep-alive>
        <component
          :is="activeTab.component"
          :selectedBatch="selectedBatch"
          :panel_height="panelHeight">
        </component>
      </keep-alive>
    </template>
  </v-container>
  </v-card>
  <RebillReverse
    v-if="modal"
    :value.sync="modal"
    :selectedBatch="selectedBatch">
  </RebillReverse>
  </v-dialog>
</template>
<script>
// Components
import promoItemTable from '@/components/BatchTables/promoItemTable'
import storeTable from '@/components/BatchTables/storeTable'
import vendorTable from '@/components/BatchTables/vendorTable'
const reversalTable = () => import('@/components/BatchTables/reversalTable')
import departmentTable from '@/components/BatchTables/departmentTable'
import ineligibleTable from '@/components/BatchTables/ineligibleTable'
const RebillReverse = () => import('@/components/RebillReverse')
// Mixins
import { displayAlert } from '@/mixins/displayAlert'
import { dateFormat } from '@/mixins/date-format'
export default {
  data () {
    return {
      expandedPanel: 0, 
      tab: 0,
      tabs: ['Vendors', 'Departments', 'Promotions', 'Parties/Stores', 'Ineligible Items'],
      tabItems: [
        { id: 0, component: 'vendorTable' },
        { id: 1, component: 'departmentTable' },
        { id: 2, component: 'promoItemTable' },
        { id: 3, component: 'storeTable' },
        { id: 4, component: 'ineligibleTable' }
      ],
      search: '',
      modal: false,
      panelHeight: 480,
      tags: {'batch_id':''}
    }
  },
  name: 'batch-details',
  props: ['value', 'selectedBatch'],
  mixins: [displayAlert, dateFormat],
  components: {
    promoItemTable,
    storeTable,
    vendorTable,
    departmentTable,
    reversalTable,
    RebillReverse,
    ineligibleTable
  },
  watch:{
    expandedPanel: {
      handler (value) {
        if (value === 0) {
          this.panelHeight = 480
        } else {
          this.panelHeight = 322
        }
      }
    }
  },
  computed: {
    isReversal () {
      return this.selectedBatch.batch_type === 'REVERSAL'
    },
    invalidStatus () {
      return ['NO_ALLOWANCES','EXCEPTION'].includes(this.selectedBatch.status)
    },
    currentTabs () {
      if (this.isReversal) return ['Reversal']
      return this.invalidStatus
        ? ['Promotions', 'Ineligible Items']
        : this.tabs
    },
    currentTabItems () {
      if (this.isReversal) {
        return [{ id: 0, component: 'reversalTable' }]
      } else if (this.invalidStatus) {
        return [
          { id: 0, component: 'promoItemTable' },
          { id: 1, component: 'ineligibleTable' }
        ]
      }
      return this.tabItems
    },
    activeTab () {
      return this.currentTabItems.find(item => item.id === this.tab)
    },
    show () {
      return (this.expandedPanel !== 0)
    },
    responsibleParties () {
      return this.$store.getters.responsibleParties
    },
    respParty () {
      return this.getResponsiblePartyName(this.selectedBatch?.responsible_party_id)
    }
  },
  methods: {
    getResponsiblePartyName (id) {
      if (this.responsibleParties.length > 0) {
        const party = this.responsibleParties.find(p => p.id === id)
        if (party) {
          return party.name
        }
      }
      return ''
    },
    async downloadRecaps () {
      this.tags.batch_id = this.selectedBatch.id
      const params1 = {
        root_key: 'ipro_portal',
        party_ids: this.selectedBatch.responsible_party_id,
        tags: JSON.stringify(this.tags)
      }
      await this.$Document.searchDocs(params1).then(res=>{
        this.doc = res.data[0];
        let docID = this.doc.id
        this.downloadFile(docID)
      }).catch(err =>{
        this.handleError(err)
      })
    },
    async downloadFile (docID){
      await this.$Document.getFile(docID).then(res => {
        let link = res.data
        window.open(link.url)
      }).catch(err =>{
        this.handleError(err)
      })
    }
  }
}
</script>
<style scoped>
.active {
  background: #fff;
  color: #37474F !important;
}
</style>